jQuery(document).ready(function($) {
	
	/*
	 * Append String To Register Form
	 *
	 * As the title suggests, this function inserts
	 * a string into the customised registration form
	 */
	$(function() {
		$('<p class="description"><strong>Note!</strong> Your username should be the first three letters of your first and family name e.g Joe Bloggs is "JoeBlo", if this is not added your username will be created like this automatically.</p>').insertAfter('#registerform p:first');
	});
});