/**
 * Bootstrap 4 Inits + Handlers
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
jQuery(document).ready(function($) {

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('.case-study').each(function() {
        
        /*
         * @TODO
         */
        $(this).find('.case-study-body').on('click', '.case-study-more', function(evt) {
            
            evt.preventDefault();
            $(evt.target).closest('.case-study-body').find('.case-study-split').toggleClass('d-none');
            
            if ($(evt.target).text() == 'Read more') {
                $(evt.target).text('Read less');
            } else {
                $(evt.target).text('Read more');
            }
        });
    });

    // ---------------------------------------------------------------------


    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('.case-studyx').each(function() {
        
        var case_study_body  = $(this).find('.case-study-body');
        var case_study_slide = case_study_body.find('.case-study-slider');
        
        case_study_slide.on('init', function(event, slick){

            var paragraphs       = [];
            var case_study_more  = case_study_body.find('.case-study-more');
            var case_study_text  = case_study_body.find('.case-study-text');
            var case_study_split = case_study_body.find('.case-study-split');

            case_study_text.find('p').each(function(){
                paragraphs.push($(this).html().split('<br />'));
            });

            if (case_study_slide.hasClass('slick-initialized')) {

                var text_width = case_study_text.width();
                var text_height = case_study_text.height();
                var slider_height = event.target.offsetHeight;

                if (text_height > slider_height + 44) {

                    case_study_more.toggleClass('show');
                    case_study_text.css({
                        'height': slider_height + 'px',
                        'overflow': 'hidden'
                    });

                    var ratio = text_width/slider_height;

                    var case_study_text_copy = case_study_text.clone();
                    case_study_split.append(case_study_text_copy);

                    var text_remain = text_height - slider_height;
                    var split_height = text_remain / ratio + 44;

                    case_study_split.css({
                        'height': split_height + 'px',
                        'overflow': 'hidden'
                    });

                    var new_case_study_text = case_study_split.find('.case-study-text');

                    new_case_study_text.css({
                        'height': '',
                        'position': 'absolute',
                        'bottom': 0,
                    });
                }
            }
        });

        /*
         * @TODO
         */
        $(this).find('.case-study-body').on('click', '.case-study-more', function(evt) {
            
            evt.preventDefault();
            $(evt.target).closest('.case-study-body').find('.case-study-split').toggleClass('d-none');
            
            if ($(evt.target).text() == 'Read more') {
                $(evt.target).text('Read less');
            } else {
                $(evt.target).text('Read more');
            }
        });
    });

    // ---------------------------------------------------------------------

    /*
     * Inject CSS into the embedded Twitter Tweet
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('#twitter-widget-0x').waitUntilExists(function(){
        var rules = '';
        var root  = $('#twitter-widget-0').get(0);
        var style = document.createElement('style');
        
        //var rules = '.CallToAction-text,.Tweet-body.e-entry-content a{color:#652D86!important;}'
        root.shadowRoot.appendChild(style);
        style.type = 'text/css';
        
        if (style.styleSheet){
            // Required for IE8 and below
            style.styleSheet.cssText = rules;
        } else {
            style.appendChild(document.createTextNode(rules));
        }
    });

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('.post-video').on('click', function(e) {
        
        e.preventDefault();
        $('.post-video-player').each(function() {
            $(this).get(0).pause();
        });
        
        var videoElement = $(this).find('.post-video-player').get(0);
        $(this).find('.post-video-play-button').addClass('d-none');
        videoElement.paused ? videoElement.play() : videoElement.pause();
        
        if (videoElement.hasAttribute('poster')) {
            videoElement.removeAttribute('poster');
            videoElement.setAttribute('controls','controls');
        }
    });

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('#mission-video-modal').length > 0) {
        $('#mission-video-modal').on('hidden.bs.modal', function() {
            $('#mission-video-iframe').removeAttr('src');
        });
        $('#mission-video-modal').on('show.bs.modal', function(e) {
            var invoker  = $(e.relatedTarget);
            var videoSrc = invoker.attr('data-src');
            $('#mission-video-iframe').attr('src', videoSrc + "&amp;controls=1&amp;autoplay=1");
        });
    }

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('#sector-select').on('change', function(evt) {
        var sector_id = $(this).val();
        if (sector_id != null) {
            var url = window.location.href.split('?')[0];
            var paged = url.substring(url.length - 7);
            if (paged.indexOf('page/') !== -1) {
                url = url.substring(0, url.length - 7);
            }
            var sep = QuantumAjax.hasQueryVars() ? '?' : '&';
            url += sep + 'sector_id=' + sector_id;
            var sortby = QuantumAjax.getQueryVariable('sortby');
            if (sortby) {
                url += '&sortby=' + sortby;
            }
            window.location = url;
        }
    });

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('#sortby-select').on('change', function(evt) {
        var sortby = $(this).val();
        if (sortby != null) {
            var url = window.location.href.split('?')[0];
            var paged = url.substring(url.length - 7);
            if (paged.indexOf('page/') !== -1) {
                url = url.substring(0, url.length - 7);
            }
            var sep = QuantumAjax.hasQueryVars() ? '?' : '&';
            var sector_id = QuantumAjax.getQueryVariable('sector_id');
            if (sector_id) {
                url += sep + 'sector_id=' + sector_id;
            }
            sep = sector_id ? '&' : '?';
            url += sep + '&sortby=' + sortby;
            window.location = url;
        }
    });

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('.close-modal').on('click', function(e) {
        e.preventDefault();
        $('.checkout-account-options .custom-control-input').prop('checked', false);
    });
});
