/**
 * Slick Carousel Inits + Handles
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
jQuery(document).ready(function($) {

    /*
     * Hero Sliders.
     */
    if ($('.hero-slider').length > 0) {
        $('.hero-slider').each(function() {
            var slider = $(this);
            slider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: true,
                infinite: true,
                fade: true,
                cssEase: 'linear',
                autoplay: true,
                adaptiveHeight: 0,
                accessibility: 0,
                rows: 0
            });
        });        
    }
});